:root {
  --bg: hsl(200, 7%, 9%);
}

@font-face {
  font-family: 'Malnor Sans';
  src: url('../fonts/MalnorSansRegular.eot');
  src: local('Malnor Sans Regular'), local('MalnorSansRegular'),
    url('../fonts/MalnorSansRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MalnorSansRegular.woff2') format('woff2'),
    url('../fonts/MalnorSansRegular.woff') format('woff'),
    url('../fonts/MalnorSansRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Malnor Sans';
  src: url('../fonts/MalnorSansMedium.eot');
  src: local('Malnor Sans Medium'), local('MalnorSansMedium'),
    url('../fonts/MalnorSansMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MalnorSansMedium.woff2') format('woff2'),
    url('../fonts/MalnorSansMedium.woff') format('woff'),
    url('../fonts/MalnorSansMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading {
  .container {
    opacity: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Malnor Sans';
  font-weight: normal;
  font-size: 16px;
  background-color: var(--bg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 48rem) {
    height: 100vh;
    flex-direction: row;
  }
}

.hero {
  position: relative;
  width: 100%;
  margin-top: -80px;

  @media (min-width: 48rem) {
    width: 50%;
    height: 100%;
    margin-top: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@keyframes appear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem;
  background-color: var(--bg);
  width: 90%;
  margin-top: -32px;
  position: relative;
  z-index: 1;
  
  @media (min-width: 48rem) {
    opacity: 0;
    transform: translateX(-4px);
    width: 50%;
    margin-top: 0;
  }

  @media (min-width: 80rem) {
    padding: 3rem;
  }
}
.headline {
  font-size: max(1.2rem, 2vw);
  font-weight: bold;
  font-weight: 500;
  word-spacing: -1pt;
  letter-spacing: -.333pt;
  line-height: 1.4;
  color: rgba($color: #FFF, $alpha: .95);

  span {
    background-image: -webkit-linear-gradient(45deg, #f35626, #feab3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 15s infinite linear;
    animation: hue 15s infinite linear;
  }
}

a {
  text-decoration: none;
}

.cta {
  -webkit-transform: translateZ(0);
  transform: translate3d(0);
  background: hsl(200, 7%, 14%);
  border: 2px solid transparent;
  color: rgba($color: #FFF, $alpha: .8);
  font-size: 1rem;
  height: 64px;
  width: 260px;
  border-radius: 32px;
  margin-top: 2rem;
  display: flex;
  font-weight: 400;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.33rem rem;
  line-height: 1;
  position: relative;

  &:hover {
    background: hsl(200, 7%, 17%);
  }

  &::after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../images/copy.svg") 0 0 no-repeat;
    background-size: 18px;
    opacity: 0.33;
    position: absolute;
    right: 16px;
  }
  
  &.copied {
    pointer-events:none;
    background: hsl(200, 7%, 15%);
    &::after {
      visibility: hidden;
    }
  }
}

.network {
  margin-top: 3rem;

  a {
    opacity: .6;
    transition: all .2s;
    &:hover {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: .8rem;
    }
  }
}


.hero {  
  &::before {
    transform-origin: 100% 50%;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--bg);
    display: block;
    z-index: 1;
  }
  img {
    @media (min-width: 48rem) {
      transform: translateX(-3px);
    }
  }
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

@keyframes slide {
  to {
    transform: scaleX(0);
  }
}

@keyframes littleSlide {
  to {
    transform: translateX(0);
  }
}

.loaded {
  .content {
    @media (min-width: 48rem) {
      animation: appear 250ms forwards 350ms ease-in;
    }
  }

  .hero {
    &::before {
      animation: slide 300ms ease-in-out forwards 200ms;
    }

    img {
      @media (min-width: 48rem) {
        transform: translateX(-3px);
      }

      animation: littleSlide 500ms ease-in forwards 200ms;
    }
  }
}